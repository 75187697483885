import PropTypes from 'prop-types'
import React from 'react'
import TCQuickViewWrapper from '../TCQuickViewWrapper'

const CollectionCard = ({
  advertise,
  id,
  image,
  isLoading,
  more_than_one_variant,
  name,
  oldPrice,
  onSale,
  price,
  quickVieModalData,
  topLabel,
  url,
  buttonStyles,
}) => {
  const productUrl =
    typeof Spree !== 'undefined' && Spree.routes ? Spree.routes.product(id) : ''

  return (
    <div className="collectionCard_item col-6 col-sm-4 col-md-3 col-xxl-2">
      <div className="collectionCard">
        <div className="collectionCard_wrapper">
          <a
            className="collectionCard_imgLink"
            href={url || productUrl}
            tabIndex="-1"
          >
            {isLoading && <div className="collectionCard_backdrop-layer"></div>}
            <div className="collectionCard_topLabel">{topLabel}</div>
            {advertise && (
              <span className={'collectionCard_advertise'}>
                {Spree.translation('spree.tcred.on_sale')}
              </span>
            )}
            <div className="collectionCard_media responsiveImage">
              <img
                alt={image}
                className="collectionCard_img responsiveImage_img lazyload"
                data-sizes="auto"
                src={image}
              />
              {isLoading ? null : (
                <TCQuickViewWrapper
                  buttonStyles={buttonStyles}
                  config={quickVieModalData}
                  id={id}
                  product_url={Spree.routes.product(id)}
                />
              )}
            </div>
            <div className="collectionCard_collect"></div>
            <div className="collectionCard_content">
              <div className="collectionCard_textWrapper">
                <h4 className="collectionCard_title">{name}</h4>
                <div
                  className={`collectionCard_label ${onSale ? 'onSale' : ''}`}
                >
                  <span>
                    {more_than_one_variant
                      ? `${Spree.translation('from')} `
                      : null}
                  </span>
                  <span>{price}</span>
                  <span className="price-label">{onSale ? oldPrice : ''}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default CollectionCard

CollectionCard.propTypes = {
  advertise: PropTypes.bool,
  buttonStyles: PropTypes.object,
  id: PropTypes.number,
  image: PropTypes.string,
  isLoading: PropTypes.bool,
  more_than_one_variant: PropTypes.bool,
  name: PropTypes.string,
  oldPrice: PropTypes.string,
  onSale: PropTypes.bool,
  price: PropTypes.string,
  quickVieModalData: PropTypes.object,
  topLabel: PropTypes.string,
  url: PropTypes.string,
}
